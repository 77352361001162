<template>
  <div class="scheme">
    <BaseDialog
      title="透析方案模板列表"
      :isshow="visible"
      @handleShow="handleShow"
      width="80%"
      height="auto"
    >
      <div class="schemeBox">
        <div class="templateBut">
          <el-button class="color-main" type="primary" @click="addTemplate">
            <i class="fa fa-search"></i>新增模板
          </el-button>
        </div>
        <div class="templateTable">
          <el-table
            v-loading="tableLoading"
            ref="singleTableRef"
            :data="tableData"
            size="small"
            border
            max-height="164"
          >
            >
            <el-table-column label="透析方式">
              <template #default="scope">
                {{
                  gitDictVal(dictList[100000000], scope.row.dict_dialysis_type)
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="assign_user"
              label="制定人"
            ></el-table-column>
            <el-table-column prop="updated_time" label="制定时间" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column fixed="right" label="操作" width="160">
              <template #default="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.prevent.stop="modTemplate(scope.row)"
                >
                  <i class="txIons modify"></i>
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click.prevent.stop="delDialysis(scope.row)"
                >
                  <i class="txIons del"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="contents" v-if="isAddTemplate">
          <el-scrollbar>
            <div class="padRight-20">
              <div class="userItem">
                <el-form
                  ref="ruleFormsRef"
                  :model="ruleForm"
                  :rules="Rule.DIALYSIS"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-104"
                >
                  <el-form-item label="透析方式：" prop="dict_dialysis_type">
                    <el-select
                      v-model="ruleForm.dict_dialysis_type"
                      @change="dialysisTypeCha"
                      placeholder="请选择透析方式"
                    >
                      <el-option
                        v-for="item in dictList[100000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="干体重：" prop="dry_weight">
                    <el-input
                      v-model="ruleForm.dry_weight"
                      placeholder="请输入干体重"
                    >
                      <template #append>KG</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="附加体重：">
                    <el-input
                      v-model="ruleForm.add_weight"
                      placeholder="请输入"
                    >
                      <template #append>KG</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="lstwo" label="透析时长：">
                    <el-input
                      v-model="ruleForm.dialysis_hour"
                      placeholder="请输入"
                    >
                      <template #append>时</template>
                    </el-input>
                    <el-input
                      v-model="ruleForm.dialysis_min"
                      placeholder="请输入"
                    >
                      <template #append>分</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="最大脱水：">
                    <el-input
                      v-model="ruleForm.max_tuoshui_num"
                      placeholder="请输入"
                    >
                      <template #append>KG</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="lstwo" label="血流速：">
                    <el-input
                      v-model="ruleForm.xueliu_speed_min"
                      placeholder="请输入"
                    >
                      <template #append>至</template>
                    </el-input>
                    <el-input
                      v-model="ruleForm.xueliu_speed_max"
                      placeholder="请输入"
                    >
                      <template #append>ml/min</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="回血量：">
                    <el-input
                      v-model="ruleForm.huixue_num"
                      placeholder="请输入"
                    >
                      <template #append>ml</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="回血泵速：">
                    <el-input
                      v-model="ruleForm.huixue_beng_speed"
                      placeholder="请输入"
                    >
                      <template #append>ml/min</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="超滤方式：">
                    <el-select
                      v-model="ruleForm.dict_chaolv_mode"
                      placeholder="请选择超滤方式"
                    >
                      <el-option
                        v-for="item in dictList[339400000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="冲水方式：">
                    <el-select
                      v-model="ruleForm.dict_flush_mode"
                      placeholder="请选择冲水方式"
                    >
                      <el-option
                        v-for="item in dictList[339500000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="护理等级：">
                    <el-select
                      v-model="ruleForm.dict_huli_level"
                      placeholder="请选择护理等级"
                    >
                      <el-option
                        v-for="item in dictList[119000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="透析频次：">
                    <el-select
                      v-model="ruleForm.dict_dialysis_cycle"
                      placeholder="请选择透析频次"
                    >
                      <el-option
                        v-for="item in dictList[117000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="制定时间：">
                    <el-date-picker
                      v-model="ruleForm.make_time"
                      type="date"
                      value-format="YYYY-MM-DD"
                      placeholder="请选择制定时间"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="制定人：">
                    <el-select
                      v-model="ruleForm.assign_user_id"
                      ref="selectRef"
                      placeholder="请选择制定人"
                    >
                      <el-option
                        v-for="item in userNameList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <el-form
                  ref="ruleFormRef"
                  :model="ruleForm"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-104"
                >
                  <el-form-item label="透析液温度：">
                    <el-input
                      v-model="ruleForm.touxiye_temp"
                      placeholder="请输入"
                    >
                      <template #append>℃</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="透析液流速">
                    <el-input
                      v-model="ruleForm.touxiye_speed"
                      placeholder="请输入"
                    >
                      <template #append>ml/min</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="K：">
                    <el-input v-model="ruleForm.k" placeholder="请输入">
                      <template #append>mmol/L</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Ca：">
                    <el-input v-model="ruleForm.ca" placeholder="请输入">
                      <template #append>mmol/L</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Na：">
                    <el-input v-model="ruleForm.na" placeholder="请输入">
                      <template #append>mmol/L</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="HCO3-：">
                    <el-input v-model="ruleForm.hco3" placeholder="请输入">
                      <template #append>mmol/L</template>
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <el-form
                  ref="ruleFormRef"
                  :model="ruleForm"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-104"
                >
                  <el-form-item label="抗凝剂：">
                    <el-select
                      v-model="ruleForm.dict_kangning"
                      placeholder="请选择抗凝剂"
                    >
                      <el-option
                        v-for="item in dictList[116000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="select" label="首剂：">
                    <el-input
                      v-model="ruleForm.kangning_shouji_num"
                      placeholder="请输入"
                    >
                      <template #append>
                        <el-select
                          v-model="ruleForm.dict_kangning_shouji_unit"
                          placeholder="请选择单位"
                        >
                          <el-option
                            v-for="item in dictList[157000000]"
                            :key="item.id"
                            :label="item.name"
                            :value="item.code"
                          ></el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="lstwo" label="维持时间：">
                    <el-input
                      v-model="ruleForm.kangning_add_time_hour"
                      placeholder="请输入"
                    >
                      <template #append>时</template>
                    </el-input>
                    <el-input
                      v-model="ruleForm.kangning_add_time_min"
                      placeholder="请输入"
                    >
                      <template #append>分</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="追加维持：">
                    <el-select
                      v-model="ruleForm.dict_kangning_add"
                      placeholder="请选择追加维持"
                    >
                      <el-option
                        v-for="item in dictList[116000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="select" label="追加维持：">
                    <el-input
                      v-model="ruleForm.kangning_add_num"
                      placeholder="请输入剂量"
                    >
                      <template #append>
                        <el-select
                          v-model="ruleForm.dict_kangning_add_num_unit"
                          placeholder="请选择单位"
                        >
                          <el-option
                            v-for="item in dictList[157000000]"
                            :key="item.id"
                            :label="item.name"
                            :value="item.code"
                          ></el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <el-form
                  ref="ruleFormRef"
                  :model="ruleForm"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-104"
                >
                  <el-form-item label="血透器：">
                    <el-select
                      v-model="ruleForm.dict_touxiqi"
                      placeholder="请选择血透器"
                    >
                      <el-option
                        v-for="item in dictList[113000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="血滤器：">
                    <el-select
                      v-model="ruleForm.dict_xuelvqi"
                      placeholder="请选择血滤器"
                    >
                      <el-option
                        v-for="item in dictList[114000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="灌流器：">
                    <el-select
                      v-model="ruleForm.dict_guanliuqi"
                      placeholder="请选择灌流器"
                    >
                      <el-option
                        v-for="item in dictList[115000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="置换方式：">
                    <el-select
                      v-model="ruleForm.dict_zhihuan_type"
                      :disabled="isDisabled"
                      placeholder="请选择置换方式"
                    >
                      <el-option
                        v-for="item in dictList[118000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="置换总量：">
                    <el-input
                      :disabled="isDisabled"
                      v-model="ruleForm.zhihuan_num"
                      placeholder="请输入"
                    >
                      <template #append>L</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="" prop="name"> </el-form-item>
                  <el-form-item class="lstwo" label="置换流速：">
                    <el-input
                      :disabled="isDisabled"
                      v-model="ruleForm.zhihuan_speed_min"
                    >
                      <template #append>-</template>
                    </el-input>
                    <el-input
                      :disabled="isDisabled"
                      v-model="ruleForm.zhihuan_speed_max"
                    >
                      <template #append>ml/min</template>
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <el-form
                  ref="ruleFormRef"
                  :model="ruleForm"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForms col-333 lable-104"
                >
                  <el-form-item class="textarea" label="备注：">
                    <el-input
                      v-model="ruleForm.remark"
                      maxlength="1000"
                      :rows="5"
                      placeholder="请输入备注"
                      show-word-limit
                      type="textarea"
                    />
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer" v-if="isAddTemplate">
          <el-button
            class="color-cancel"
            type="primary"
            @click="isAddTemplate = false"
          >
            取消
          </el-button>
          <el-button
            @click="saveDialy"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            保存
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import service from '@/utils/request'
import Rule from '@/utils/rule'
import { ElMessageBox, ElMessage } from 'element-plus'
import { ruleTimeYear, gitDictVal } from '@/utils/tool.js'
import BaseDialog from '@/components/Dialog/index.vue'
export default {
  name: 'dialysisScheme',
  components: {
    BaseDialog,
  },
  setup() {
    const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      dataForm: {},
      ruleForm: {},
      dictList: {},
      tableData: [],
      tableLoading: false,
      userNameList: [
        {
          name: localStorage.getItem('ms_username'),
          code: userInfo.uid,
          id: userInfo.uid,
        },
      ],
      Rule: Rule,
      id: '',
      visible: false,
      isAddTemplate: false,
      saveLoading: false,
      ruleFormsRef: null,
      isDisabled: true,
      assignUserId: '',
    })

    const initData = async (id, row) => {
      state.visible = true
      state.id = id
      state.dictList = row
      state.isAddTemplate = false
      state.assignUserId = ''
      getList()
    }

    const formInit = () => {
      state.ruleForm = {}
      state.ruleForm = {
        assign_user_id: userInfo.uid,
        dict_dialysis_type: '100110000',
        add_weight: 0,
        dialysis_hour: 4,
        dialysis_min: 0,
        xueliu_speed_min: 250,
        dict_huli_level: '119110000',
        make_time: ruleTimeYear(new Date()),
        touxiye_temp: '36.5',
        touxiye_speed: '500',
        k: '2',
        ca: '1.5',
        na: '138',
        hco3: '35',
        kangning_add_time_hour: 4,
        kangning_add_time_min: 0,
        dict_kangning_shouji_unit: '157130000',
        dict_kangning_add_num_unit: '157130000',
      }
    }

    const getList = async () => {
      state.tableLoading = true
      let res = await service.post(
        '/api/patient_dialysis/get_patient_dialysis_tpl_list'
      )
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data.patient_dialysis_tpl_list
      }
    }

    const dialysisTypeCha = (val) => {
      if (
        val === '100120000' ||
        val === '100150000' ||
        val === '1388016580952096' ||
        val === '1445308508667936'
      ) {
        state.isDisabled = false
      } else {
        state.isDisabled = true
      }
    }

    const handleShow = (val) => {
      state.visible = val
    }

    const addTemplate = () => {
      state.isAddTemplate = true
      formInit()
    }

    const saveDialy = () => {
      state.ruleFormsRef.validate(async (valid) => {
        if (valid) {
          state.ruleForm.patient_id = state.id
          if (state.ruleForm.assign_user_id != userInfo.uid) {
            state.ruleForm.assign_user_id = state.assignUserId
          }
          let url = ''
          if (state.ruleForm.id) {
            state.ruleForm.patient_dialysis_tpl_id = state.ruleForm.id
            url = '/api/patient_dialysis/edit_patient_dialysis_tpl'
          } else {
            url = '/api/patient_dialysis/create_patient_dialysis_tpl'
          }
          state.saveLoading = true
          let res = await service.post(url, state.ruleForm)
          state.saveLoading = false
          if (res.code === 0) {
            ElMessage.success(res.msg)
            state.isAddTemplate = false
            getList()
          }
        }
      })
    }
    const modTemplate = (row) => {
      state.ruleForm = row
      dialysisTypeCha(row.dict_dialysis_type)
      state.isAddTemplate = true
      if (row.assign_user_id != userInfo.uid) {
        state.assignUserId = JSON.parse(JSON.stringify(row)).assign_user_id
        state.ruleForm.assign_user_id = row.assign_user
      }
    }
    const delDialysis = (item) => {
      ElMessageBox.confirm('您确认要删除吗?', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post(
            '/api/patient_dialysis/delete_patient_dialysis_tpl',
            {
              patient_dialysis_tpl_id: item.id,
            }
          )
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getList()
          }
        })
        .catch(() => {})
    }

    return {
      ...toRefs(state),
      initData,
      handleShow,
      addTemplate,
      saveDialy,
      dialysisTypeCha,
      gitDictVal,
      modTemplate,
      delDialysis,
    }
  },
}
</script>

<style scoped lang="scss">
.templateBut {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 20px;
}
.templateTable {
  margin-bottom: 20px;
}
.contents {
  height: 400px;
  overflow: hidden;
  .userItem {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 30px;
  }
  .demo-ruleForms .el-form-item {
    width: 100%;
    margin-right: 0;
  }
  .el-form-item {
    width: 30%;
    margin-right: 5%;
    &.addr {
      width: 65%;
      margin-right: 0;
    }
    // &:deep(.el-form-item__content) {
    //   width: calc(100% - 104px);
    //   // min-width: 150px;
    // }
    &:deep(.el-input-group__append .el-select) {
      width: auto;
    }
  }
  .el-form-item:nth-child(3n + 3) {
    margin-right: 0;
  }
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;
  .color-cancel {
    background: #ffffff;
    color: #3166ae;
    margin-right: 10px;
  }
}
</style>
